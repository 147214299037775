"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
exports.TrelloBoard = (props) => {
    React.useEffect(() => {
        console.log("useEffect");
        const script = document.createElement("script");
        script.src = "https://p.trellocdn.com/embed.min.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            console.log("returning");
            document.body.removeChild(script);
        };
    }, []);
    return (React.createElement("blockquote", { className: "trello-board-compact" },
        React.createElement("a", { href: props.boardUrl, target: "_blank" }, "Trello Board")));
};
