"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
require("./Resume.css");
const amazon_scout_jpg_1 = require("../../static/images/amazon-scout.jpg");
const riptide_logo_jpg_1 = require("../../static/images/riptide-logo.jpg");
const berkeley_seal_jpg_1 = require("../../static/images/berkeley-seal.jpg");
const amazon_logo_jpg_1 = require("../../static/images/amazon-logo.jpg");
const ResumeItem_1 = require("../../components/resume-item/ResumeItem");
exports.Resume = () => {
    return (React.createElement("div", null,
        React.createElement("div", { id: "resume", className: "section container resume-container" },
            React.createElement("h1", null, "Experience"),
            React.createElement("hr", null),
            React.createElement(ResumeItem_1.ResumeItem, { title: "Amazon Scout", subtitle: "Software Development Engineer", image: amazon_scout_jpg_1.default },
                "I'm currently building autonomous sidewalk delivery robots with the Amazon Scout team! In general terms, my team handles the communications on and off of the Scout. As we're still in an early stage, please refer one of the Amazon blog posts",
                " ",
                React.createElement("a", { href: "https://blog.aboutamazon.com/transportation/meet-scout", target: "_blank" }, "here"),
                " ",
                "and",
                " ",
                React.createElement("a", { href: "https://blog.aboutamazon.com/transportation/whats-next-for-amazon-scout", target: "_blank" }, "here"),
                " ",
                "to find out more."),
            React.createElement(ResumeItem_1.ResumeItem, { title: "Riptide Messaging", subtitle: "Lead iOS Engineer", image: riptide_logo_jpg_1.default, reverse: true }, "Riptide is a B2C and B2B integrated messaging and payments service that helps businesses better connect to their suppliers and customers. During my time at Riptide, I contributed primarily to building the native iOS application from the ground up as well as some auxiliary backend and Javascript frontened work."),
            React.createElement(ResumeItem_1.ResumeItem, { title: "Amazon.com", subtitle: "SDE Intern", image: amazon_logo_jpg_1.default }, "I worked as a SDE intern in the summer of 2017 on the Amazon Pricing team, working on internal tooling for the Amazon Pricing Engine and improving the system used for managing merchant-specified parameters."),
            React.createElement("h1", null, "Education"),
            React.createElement("hr", null),
            React.createElement(ResumeItem_1.ResumeItem, { title: "Universty of California, Berkeley", subtitle: "Bachelor of Arts - 2018", image: berkeley_seal_jpg_1.default, reverse: true }, "Proud Berkeley grad with a double major in Computer Science and Economics. Also a proud alumni of UC Choral Ensembles, in which I served as President during the 2016-2017. GO BEARS!!"))));
};
