"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const free_brands_svg_icons_1 = require("@fortawesome/free-brands-svg-icons");
require("./Navbar.css");
exports.Navbar = () => {
    const [isMenuActive, setIsMenuActive] = React.useState(false);
    return (React.createElement("nav", { className: "navbar" },
        React.createElement("div", { className: "navbar-brand" },
            React.createElement("a", { className: "navbar-item centered ", href: "#" },
                React.createElement("b", null, "Anbang Zhang")),
            React.createElement("a", { role: "button", className: `navbar-burger ${isMenuActive ? "is-active" : null}`, "aria-label": "menu", "aria-expanded": "false", onClick: () => setIsMenuActive(!isMenuActive) },
                React.createElement("span", { "aria-hidden": "true" }),
                React.createElement("span", { "aria-hidden": "true" }),
                React.createElement("span", { "aria-hidden": "true" }))),
        React.createElement("div", { className: `navbar-menu ${isMenuActive ? "is-active" : null}` },
            React.createElement("div", { className: "navbar-start" },
                React.createElement("a", { className: "navbar-item centered ", href: "#this-site" }, "This Site"),
                React.createElement("a", { className: "navbar-item centered ", href: "#resume" }, "R\u00E9sum\u00E9"),
                React.createElement("a", { className: "navbar-item centered ", href: "#roadmap" }, "Roadmap"),
                React.createElement("a", { className: "navbar-item centered ", href: "#contact-me" }, "Contact Me")),
            React.createElement("div", { className: "navbar-end" },
                React.createElement("a", { className: "navbar-item centered", href: "https://www.instagram.com/anbangz/", target: "_blank" },
                    React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_brands_svg_icons_1.faInstagram, size: "2x" })),
                React.createElement("a", { className: "navbar-item centered", href: "https://github.com/anbangz", target: "_blank" },
                    React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_brands_svg_icons_1.faGithub, size: "2x" })),
                React.createElement("a", { className: "navbar-item centered", href: "https://www.linkedin.com/in/anbang-zhang-1141b18b/", target: "_blank" },
                    React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_brands_svg_icons_1.faLinkedin, size: "2x" }))))));
};
