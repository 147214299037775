"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const TrelloBoard_1 = require("../../components/widgets/trello/TrelloBoard");
exports.Roadmap = () => {
    return (React.createElement("div", { className: "section container", id: "roadmap" },
        React.createElement("h1", null, "Roadmaps"),
        React.createElement("hr", null),
        "Interested in seeing what I'm up to? I use Trello to manage a lot of things in my life - including this website! Unfortunately, due to security concerns, Trello (and other project management solutions in general) do not allow embeds of full boards on external sites. Please check out the boards below to check out what I'm working on:",
        React.createElement("div", { style: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
                margin: "10px 0"
            } },
            React.createElement(TrelloBoard_1.TrelloBoard, { boardUrl: "https://trello.com/b/R4QbTXUb/personal" }),
            React.createElement(TrelloBoard_1.TrelloBoard, { boardUrl: "https://trello.com/b/YdDVuKer/personal-website" }))));
};
