"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
require("./Footer.css");
exports.Footer = () => {
    return (React.createElement("footer", { className: "content" },
        React.createElement("div", { className: "footer__content" },
            React.createElement("div", null, "\u00A9 2020 Anbang Zhang"),
            React.createElement("a", { href: "https://github.com/anbangz/react_personal" }, "GitHub repo"))));
};
