"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const Homepage_1 = require("./views/home/Homepage");
const Navbar_1 = require("./views/navbar/Navbar");
// App-wide CSS import
require("./App.css");
exports.App = () => (React.createElement(react_router_dom_1.BrowserRouter, null,
    React.createElement("div", { className: "app-layout" },
        React.createElement("div", { className: "app-layout__navbar" },
            React.createElement(Navbar_1.Navbar, null)),
        React.createElement("div", { className: "app-layout__body" },
            React.createElement(react_router_dom_1.Route, { exact: true, path: "/", component: Homepage_1.Homepage })))));
