"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const portrait_jpg_1 = require("../../static/images/portrait.jpg");
require("./TitleBanner.css");
exports.TitleBanner = () => {
    return (React.createElement("div", { id: "title-banner", className: "section title-banner" },
        React.createElement("img", { className: "title-banner__portrait", src: portrait_jpg_1.default }),
        React.createElement("div", { className: "title-banner__description" },
            React.createElement("h1", null, "Hi! I'm Anbang."),
            React.createElement("h3", null, "I'm a software engineer currently living in Seattle."))));
};
