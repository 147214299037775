"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Resume_1 = require("../resume/Resume");
const ContactMe_1 = require("../contact-me/ContactMe");
const Roadmap_1 = require("../roadmap/Roadmap");
const ThisSite_1 = require("../this-site/ThisSite");
const Footer_1 = require("../footer/Footer");
const TitleBanner_1 = require("../title-banner/TitleBanner");
exports.Homepage = () => {
    return (React.createElement("div", null,
        React.createElement(TitleBanner_1.TitleBanner, null),
        React.createElement(ThisSite_1.ThisSite, null),
        React.createElement(Resume_1.Resume, null),
        React.createElement(Roadmap_1.Roadmap, null),
        React.createElement(ContactMe_1.ContactMe, null),
        React.createElement(Footer_1.Footer, null)));
};
