"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const contactMe = () => {
    return (React.createElement("div", { className: "section container", id: "contact-me" },
        React.createElement("h1", null, "Contact Me"),
        React.createElement("hr", null),
        "Working on improving this experience! In the meantime, feel free to send me an email at",
        React.createElement("a", { href: "mailto:anbangzhang21@gmail.com" }, " anbangzhang21@gmail.com")));
};
exports.ContactMe = contactMe;
