"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
require("./ResumeItem.css");
exports.ResumeItem = (props) => {
    const className = `experience-item ${props.reverse ? "experience-item--reverse" : null}`;
    return (React.createElement("div", { className: className },
        React.createElement("img", { className: "experience-item__image", src: props.image }),
        React.createElement("div", { className: "experience-item__text" },
            React.createElement("h2", null, props.title),
            React.createElement("h3", null, props.subtitle),
            React.createElement("div", { className: "experience-item__description" }, props.children))));
};
